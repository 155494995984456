import FilterMenu from 'src/components/Overlays/FiltersMenu/FilterMenu'
import { useCurrentCompanyContext } from 'src/context/CurrentCompanyContext'
import { connectedStatusOptions } from 'src/pages/CallAnalyticsPage/utils'
import { callStatusOptions } from 'src/pages/CallAnalyticsPage/utils'
import { callDirectionOptions } from 'src/pages/CallAnalyticsPage/utils'
import { callTypeOptions, updateUrl } from 'src/pages/CallAnalyticsPage/utils'

const Filters = ({
  callDirections,
  setCallDirections,
  callTypes,
  setCallTypes,
  callStatuses,
  setCallStatuses,
  selectedConnectedStatus,
  setSelectedConnectedStatus,
}) => {
  const company = useCurrentCompanyContext()
  const isVoiceAgentEnabled = company?.voiceAgentEnabled
  return (
    <FilterMenu
      filters={[
        {
          title: 'Call Direction',
          selected: callDirections,
          onChange: (value) => {
            setCallDirections(value)
            updateUrl('callDirections', value.join(','))
          },
          options: callDirectionOptions,
        },
        {
          title: 'Call Type',
          selected: callTypes,
          onChange: (value) => {
            setCallTypes(value)
            updateUrl('callTypes', value.join(','))
          },
          options: callTypeOptions,
        },
        {
          title: 'Call Status',
          selected: callStatuses,
          onChange: (value) => {
            setCallStatuses(value)
            updateUrl('callStatuses', value.join(','))
          },
          options: callStatusOptions,
        },
        ...(isVoiceAgentEnabled
          ? [
              {
                title: `Voice Agent Status`,
                selected: selectedConnectedStatus,
                onChange: (value) => {
                  setSelectedConnectedStatus(value)
                  updateUrl('connectedStatus', value.join(','))
                },
                options: connectedStatusOptions,
              },
            ]
          : []),
      ]}
    />
  )
}

export default Filters
