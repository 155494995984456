export const GET_VOE_LIST = gql`
  query VerificationOfEmployments(
    $filters: VoEFiltersInput
    $pageInfo: PageInfoInput
  ) {
    verificationOfEmployments(filters: $filters, pageInfo: $pageInfo) {
      totalCount
      items {
        voeId
        createdAt
        updatedAt
        type
        status
        dotNumber
        driverName
        companyName
        contactName
        email
        phoneNumber
        faxNumber
        platform
        shouldContactEmployer
        safetyRequestConsentFileUrl
        downloadResponseFileUrl
        requestContent {
          voeRequestContentId
          startDate
          endDate
          positionHeld
          reasonForLeaving
          isDriverTerminated
          isDriverSubjectToFMCSRs
          isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        }
        document {
          documentId
          fileName
          fileType
          userStatus
        }
        applicant {
          applicantId
          firstName
          lastName
        }
        auditTrailLogs {
          auditTrailId
          createdAt
          type
          title
          message
          email
          phone
          fax
        }
      }
    }
  }
`

export const CREATE_VOE = gql`
  mutation CreateVerificationOfEmployment(
    $input: CreateVerificationOfEmploymentInput!
  ) {
    createVerificationOfEmployment(input: $input) {
      voeId
      createdAt
      updatedAt
      type
      status
      driverName
      ssn
      dob
      completionDate
      dotNumber
      companyName
      contactName
      email
      phoneNumber
      faxNumber
      platform
      shouldContactEmployer
      uploadUrl
    }
  }
`

export const DELETE_VOE = gql`
  mutation DeleteVerificationOfEmployment($id: String!) {
    deleteVerificationOfEmployment(id: $id) {
      voeId
    }
  }
`

export const SUBMIT_VOE_RESPONSE_CONTENT = gql`
  mutation SubmitResponseContent($id: String!, $input: ResponseContentInput!) {
    submitResponseContent(id: $id, input: $input) {
      voeId
      createdAt
      updatedAt
      type
      status
      driverName
      dotNumber
      companyName
      contactName
      email
      phoneNumber
      faxNumber
      platform
      shouldContactEmployer
      responseContent {
        voeResponseContentId
      }
      auditTrailLogs {
        auditTrailId
        title
        message
      }
    }
  }
`

export const GET_VOE_BY_ID = gql`
  query VerificationOfEmployment($id: String!) {
    verificationOfEmployment(id: $id) {
      voeId
      createdAt
      updatedAt
      type
      status
      driverName
      ssn
      dob
      completionDate
      assignee {
        firstName
        lastName
      }
      companyAddress {
        street
        city
        state
      }
      dotNumber
      companyName
      contactName
      email
      phoneNumber
      faxNumber
      platform
      requestContent {
        voeRequestContentId
        startDate
        endDate
        positionHeld
        reasonForLeaving
        isDriverTerminated
        isDriverSubjectToFMCSRs
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT
      }
      responseContent {
        voeResponseContentId
        startDate
        endDate
        positionHeld
        reasonForLeaving
        isDriverTerminated
        isDriverSubjectToFMCSRs
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        eligibleForRehire
      }
      shouldContactEmployer
      # document
      applicant {
        applicantId
        firstName
        middleName
        lastName
        application {
          applicationId
        }
      }
      auditTrailLogs {
        auditTrailId
        title
        message
      }
      accidents {
        accidentId
        createdAt
        updatedAt
        accidentDate
        city
        state
        description
        isPreventable
        isCommercialMotorVehicleInvolved
      }
      driverConsentFileUrl
    }
  }
`

export const GET_VOE_DRIVER_NAME_BY_ID = gql`
  query VoEDriverName($id: String!) {
    verificationOfEmployment(id: $id) {
      driverName
    }
  }
`

export const UPDATE_VOE = gql`
  mutation UpdateVerificationOfEmployment(
    $id: String!
    $input: UpdateVerificationOfEmploymentInput!
  ) {
    updateVerificationOfEmployment(id: $id, input: $input) {
      voeId
      createdAt
      updatedAt
      type
      status
      dotNumber
      companyName
      contactName
      email
      phoneNumber
      faxNumber
      platform
      shouldContactEmployer
    }
  }
`

export const SEND_INSTRUCTIONS_EMAIL = gql`
  mutation sendInstructionsEmail($emailTo: String!) {
    sendInstructionsEmail(emailTo: $emailTo)
  }
`

export const SEND_INSTRUCTIONS_EMAIL_FOR_OUTGOING_VOE = gql`
  mutation sendInstructionsEmailForOutgoingVoE(
    $emailTo: String!
    $voeId: String!
  ) {
    sendInstructionsEmailForOutgoingVoE(emailTo: $emailTo, voeId: $voeId)
  }
`

export const SUBMIT_EXTERNAL_COMPANY_RESPONSE = gql`
  mutation SubmitExternalCompanyResponse(
    $id: String!
    $input: ResponseContentInput!
  ) {
    submitExternalCompanyResponse(id: $id, input: $input) {
      voeId
      createdAt
      updatedAt
      type
      status
      driverName
      dotNumber
      companyName
      contactName
      email
      phoneNumber
      faxNumber
      platform
      shouldContactEmployer
      responseContent {
        voeResponseContentId
      }
      auditTrailLogs {
        auditTrailId
        title
        message
      }
    }
  }
`

export const MARK_VOE_AS_COMPLETED = gql`
  mutation markVoEAsCompleted($id: String!) {
    markVoEAsCompleted(id: $id)
  }
`

export const CREATE_VOE_AUDIT_TRAIL = gql`
  mutation createVoEAuditTrail($voeId: String!, $input: VoEAuditTrailInput!) {
    createVoEAuditTrail(voeId: $voeId, input: $input) {
      auditTrailId
      createdAt
      updatedAt
      type
      title
      message
      employee {
        employeeId
        firstName
        lastName
      }
      contactDate
      email
      phone
      fax
    }
  }
`
