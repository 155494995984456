import { useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { Loader } from '@mantine/core'
import { useInView } from 'react-intersection-observer'
import { Applicant } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import MiniCallLog from 'src/components/ActivityLogs/CallLog/MiniCallLog'
import NoteLog from 'src/components/ActivityLogs/NoteLog/NoteLog'
import SMSLog from 'src/components/ActivityLogs/SMSLog/SMSLog'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_ACTIVITY_LOGS } from 'src/graphql/activityLogs.graphql'

import ActivityLogInput from '../../tabs/ActivityLogTab/components/ActivityLogInput/ActivityLogInput'
import RefreshButton from '../../tabs/ActivityLogTab/components/RefreshButton'
type CommunicationType = 'NOTE' | 'SMS' | 'CALL'

const SidebarActivityLogs = ({
  applicant,
  communication = 'NOTE',
  onRegisterCall,
}: {
  applicant: Applicant
  communication?: CommunicationType
  onRegisterCall?: (activityLog) => void
}) => {
  const LIMIT = 10
  const page = 1
  const defaultFilters = {
    activityLogTypes: [communication],
  }
  const [filters, setFilters] = useState(defaultFilters)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const onError = () => {
    toast('Something went wrong, please try again.', 'error')
  }

  useEffect(() => {
    setFilters({ activityLogTypes: [communication] })
  }, [communication])

  const variables = {
    applicantId: applicant.applicantId,
    filters,
    pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
  }

  const {
    data: activityLogsResponse,
    networkStatus,
    fetchMore,
    refetch,
    loading,
  } = useQuery(GET_ACTIVITY_LOGS, {
    notifyOnNetworkStatusChange: true,
    variables,
    onError,
  })

  const refetchQueries = [
    {
      query: GET_ACTIVITY_LOGS,
      variables,
    },
  ]

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          applicantId: applicant.applicantId,
          filters,
          pageInfo: {
            offset: activityLogsResponse?.activityLogs.items.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-hidden py-2">
      {/* todo bug: fix refetch issue across components */}
      <div className="flex flex-1 flex-col-reverse gap-4 overflow-auto ">
        {communication === 'SMS' && (
          <RefreshButton loading={loading} refetch={refetch} />
        )}
        {activityLogsResponse?.activityLogs.items.map((activityLog) => {
          if (activityLog.type === 'SMS') {
            return (
              <SMSLog
                key={activityLog.activityLogId}
                applicant={applicant}
                activityLog={activityLog}
              />
            )
          } else if (activityLog.type === 'NOTE') {
            return (
              <NoteLog
                key={activityLog.activityLogId}
                activityLog={activityLog}
              />
            )
          } else if (activityLog.type === 'CALL') {
            return (
              <MiniCallLog
                key={activityLog.activityLogId}
                activityLog={activityLog}
              />
            )
          }
        })}
        {networkStatus === NetworkStatus.fetchMore && <Loader size="sm" />}
        {activityLogsResponse?.activityLogs.items.length <
          activityLogsResponse?.activityLogs.totalCount && (
          <div ref={ref} className="opacity-0">
            Inview placeholder
          </div>
        )}
      </div>

      <div>
        <ActivityLogInput
          applicantId={applicant.applicantId}
          communication={communication}
          refetchQueries={refetchQueries}
          onRegisterCall={onRegisterCall}
        />
      </div>
    </div>
  )
}

export default SidebarActivityLogs
