import { Box, CloseButton } from '@mantine/core'

import { cn } from 'src/utils'

const tagVariants = {
  primary: 'bg-doubleNickel-gray-100 text-doubleNickel-gray-700',
  secondary: 'bg-doubleNickel-brand-25 text-doubleNickel-brand-700',
  error: 'bg-doubleNickel-error-100 text-doubleNickel-error-700',
  success: 'bg-doubleNickel-success-100 text-doubleNickel-success-700',
  warning: 'bg-doubleNickel-warning-100 text-doubleNickel-warning-700',
  transparent: 'bg-transparent text-doubleNickel-gray-700',
  outline: 'border border-doubleNickel-gray-300 text-doubleNickel-gray-700',
}

const Tag = ({
  size = 'sm',
  variant = 'primary',
  showCloseButton = true,
  clickable = false,
  onClose = () => {},
  children,
  className = '',
  ...props
}) => {
  return (
    <Box
      className={cn(
        'flex flex-row items-center gap-1 text-nowrap rounded bg-doubleNickel-gray-100 text-xs font-medium capitalize text-doubleNickel-gray-700',
        clickable && 'cursor-pointer hover:opacity-80',
        size === 'xs' ? 'px-2 py-1' : 'p-2',
        tagVariants[variant],
        className
      )}
      {...props}
    >
      {children}
      {showCloseButton && <CloseButton size={'xs'} onClick={onClose} />}
    </Box>
  )
}

export default Tag
