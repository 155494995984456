import { createContext, useContext, useState } from 'react'

import { useDisclosure } from '@mantine/hooks'

const CallDrawerContext = createContext(null)

const CallDrawerContextProvider = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [activityLogId, setActivityLogId] = useState(null)

  const handleOpen = (activityLogId) => {
    setActivityLogId(activityLogId)
    open()
  }

  return (
    <CallDrawerContext.Provider
      value={{
        opened,
        close,
        handleOpen,
        activityLogId,
      }}
    >
      {children}
    </CallDrawerContext.Provider>
  )
}

export function useCallDrawerContext() {
  return useContext(CallDrawerContext)
}

export { CallDrawerContext, CallDrawerContextProvider }
