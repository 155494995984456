import { useState } from 'react'

import { CloseButton, Menu } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import dayjs from 'dayjs'

import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import IconCalendar from 'src/icons/IconCalendar'

import SimpleButton from '../Buttons/Button/SimpleButton'

const preSelectedDateRanges = [
  {
    label: 'Last 2 days',
    value: 'last2Days',
    start: dayjs().subtract(2, 'day').startOf('day').toDate(),
    end: new Date(),
  },
  {
    label: 'Last 7 days',
    value: 'last7Days',
    start: dayjs().subtract(7, 'day').startOf('day').toDate(),
    end: new Date(),
  },
  {
    label: 'Last 30 days',
    value: 'last30Days',
    start: dayjs().subtract(30, 'day').startOf('day').toDate(),
    end: new Date(),
  },
  {
    label: 'This month',
    value: 'thisMonth',
    start: dayjs().startOf('month').toDate(),
    end: new Date(),
  },
  {
    label: 'Last month',
    value: 'lastMonth',
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
]

const DateRangeMenu = ({
  startDate,
  endDate,
  handleChange,
  clearable = false,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [opened, setOpened] = useState(false)

  return (
    <StyledMenu
      closeOnItemClick={false}
      opened={opened}
      onChange={setOpened}
      shadow="md"
      width={300}
      position="bottom-end"
      withArrow
      arrowPosition="center"
    >
      <Menu.Target>
        <SimpleButton onClick={() => setShowCalendar(false)}>
          <IconCalendar className="h-4 fill-none stroke-doubleNickel-gray-700" />
          {startDate && endDate
            ? dayjs(startDate).format('MM/DD/YYYY') ===
              dayjs(endDate).format('MM/DD/YYYY')
              ? dayjs(startDate).format('MM/DD/YYYY')
              : `${dayjs(startDate).format('MM/DD/YYYY')} - ${
                  dayjs(endDate).format('MM/DD/YYYY') !== 'Invalid Date'
                    ? dayjs(endDate).format('MM/DD/YYYY')
                    : ''
                }`
            : 'Date Range'}
          {clearable && startDate && endDate && (
            <CloseButton
              className="ml-2"
              size={'sm'}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleChange([null, null])
                setOpened(false)
              }}
            />
          )}
        </SimpleButton>
      </Menu.Target>
      <Menu.Dropdown>
        {showCalendar ? (
          <DatePicker
            className="flex flex-1 items-center justify-center"
            type="range"
            size="sm"
            maxDate={new Date()}
            onChange={(value) => {
              const [start, end] = value
              const startTime = dayjs(start).startOf('day').toDate()
              const endTime = end
                ? dayjs(end).endOf('day').toDate()
                : dayjs(start).endOf('day').toDate()
              handleChange([startTime, endTime])
              if (start && end) setOpened(false)
            }}
            {...props}
          />
        ) : (
          <>
            {preSelectedDateRanges.map((dateRange) => (
              <Menu.Item
                key={dateRange.value}
                onClick={() => {
                  handleChange([dateRange.start, dateRange.end])
                  setOpened(false)
                }}
              >
                {dateRange.label}
              </Menu.Item>
            ))}
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation()
                setShowCalendar(true)
              }}
            >
              Select from calendar
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </StyledMenu>
  )
}

export default DateRangeMenu
