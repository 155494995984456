import { useEffect, useState } from 'react'

import { Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import CallRow from 'src/components/ActivityLogs/CallLog/CallRow'
import Empty from 'src/components/Feedback/Empty/Empty'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { useCallDrawerContext } from 'src/context/CallDrawerContext'
import { GET_CALLS } from 'src/graphql/activityLogs.graphql'
import { cn } from 'src/utils'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const CallTable = ({
  applicantId = null,
  searchText = null,
  callDirections = null,
  callTypes = null,
  callStatuses = null,
  connectedStatuses = null,
  createdAt = null,
  limit = 20,
  page = 1,
  setTotalPages = () => {},
  setTotalCalls = () => {},
  forceRefetch = false,
  setForceRefetch = () => {},
}) => {
  const [selectedCall, setSelectedCall] = useState(null)
  const { handleOpen: openCallDrawer } = useCallDrawerContext()

  const buildFilters = () => {
    const filters = {}

    if (applicantId) {
      filters.applicantId = applicantId
    }

    if (searchText) {
      filters.searchText = searchText
    }

    if (callDirections) {
      filters.callDirections = [...callDirections]
    }

    if (callTypes) {
      filters.callTypes = [...callTypes]
    }

    if (callStatuses) {
      filters.callStatuses = [...callStatuses]
    }

    if (createdAt) {
      filters.createdAt = createdAt
    }

    if (connectedStatuses) {
      filters.connectedStatuses = [...connectedStatuses]
    }

    return filters
  }

  const {
    data: filteredActivityLogsResponse,
    loading,
    refetch,
  } = useQuery(GET_CALLS, {
    variables: {
      filters: buildFilters(),
      pageInfo: { offset: (page - 1) * limit, limit: limit },
    },
    onCompleted: (data) => {
      setTotalPages(Math.ceil(data?.getCalls?.totalCount / limit))
      setTotalCalls(data?.getCalls?.totalCount)
    },
    onError,
  })

  const handleSelect = (call) => {
    setSelectedCall(call)
    openCallDrawer(call.activityLogId)
  }

  useEffect(() => {
    if (forceRefetch) {
      refetch()
      setForceRefetch(false)
    }
  }, [forceRefetch])

  return (
    <div className="flex h-full flex-col">
      <div
        className={cn(
          'grid grid-cols-9 bg-doubleNickel-gray-50 p-2 text-xs font-semibold text-doubleNickel-gray-700'
        )}
      >
        <div>Date</div>
        <div>Time</div>
        <div>From</div>
        <div>To</div>
        <div>Direction</div>
        <div>Status</div>

        <div>Duration</div>
        <div className="col-span-2">Tags</div>
      </div>
      <div className={cn('flex flex-col overflow-auto ')}>
        {loading ? (
          <div className="flex h-full w-full flex-row items-center justify-center">
            <Loader size={'sm'} color="gray" className="p-20" />
          </div>
        ) : filteredActivityLogsResponse?.getCalls.totalCount === 0 ? (
          <Empty
            title={'No calls found'}
            subtitle="Please try another search"
          />
        ) : (
          <div className="flex-1 overflow-auto">
            {filteredActivityLogsResponse?.getCalls?.items.map(
              (activityLog) => {
                return (
                  <CallRow
                    key={activityLog.activityLogId}
                    activityLog={activityLog}
                    handleSelect={handleSelect}
                    isSelected={
                      selectedCall?.activityLogId === activityLog.activityLogId
                    }
                  />
                )
              }
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CallTable
