export enum ApplicantCallConversionStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export enum ApplicantCallConnectedStatus {
  BOOKED = 'BOOKED',
  CALLBACK = 'CALLBACK',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  DISQUALIFIED = 'DISQUALIFIED',
  OTHER = 'OTHER',
  TRANSFERRED = 'TRANSFERRED',
}
