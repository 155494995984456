import { useState } from 'react'

import { Divider, TextInput } from '@mantine/core'

import CallTable from 'src/components/ActivityLogs/CallLog/CallTable'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import IconSearch from 'src/icons/IconSearch'
import { formatSnakeValue } from 'src/lib/formatters'

import DateRangeMenu from '../../components/Overlays/DateRangeMenu'
import {
  callTypeOptions,
  getUrlParams,
  updateUrl,
} from '../CallAnalyticsPage/utils'

import Filters from './components/Filters'

const ActivitiesPage = () => {
  const urlParams = getUrlParams()
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(urlParams.defaultStartDate)
  const [endDate, setEndDate] = useState(urlParams.defaultEndDate)
  const [selectedConnectedStatus, setSelectedConnectedStatus] = useState(
    urlParams.defaultConnectedStatus
  )
  const [callDirections, setCallDirections] = useState(
    urlParams.defaultCallDirections
  )
  const [callTypes, setCallTypes] = useState(urlParams.defaultCallTypes)
  const [callStatuses, setCallStatuses] = useState(
    urlParams.defaultCallStatuses
  )
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCalls, setTotalCalls] = useState(0)

  return (
    <div className="flex h-full w-full flex-col overflow-hidden px-5 pb-5">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="text-lg font-semibold text-doubleNickel-gray-900">
            Call history
          </div>
          <div className="text-sm text-doubleNickel-gray-600">
            {totalCalls} calls
          </div>
        </div>
        <TextInput
          className="ml-auto w-[400px]"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.currentTarget.value)}
          leftSection={
            <IconSearch className="fill-none stroke-doubleNickel-gray-700" />
          }
        />
      </div>

      <div className="my-4 flex w-full flex-row gap-2 overflow-y-hidden">
        <div className="flex flex-1 flex-row gap-2 overflow-auto">
          {callDirections.map((direction) => (
            <Tag
              key={direction}
              onClose={() => {
                const newDirections = callDirections.filter(
                  (item) => item !== direction
                )
                updateUrl('callDirections', newDirections.join(','))
                setCallDirections(newDirections)
              }}
            >
              {formatSnakeValue(direction)}
            </Tag>
          ))}
          {callTypes.map((type) => (
            <Tag
              key={type}
              onClose={() => {
                const newCallTypes = callTypes.filter((item) => item !== type)
                updateUrl('callTypes', newCallTypes.join(','))
                setCallTypes(newCallTypes)
              }}
            >
              {callTypeOptions.find((option) => option.value === type).label}
            </Tag>
          ))}
          {callStatuses.map((status) => (
            <Tag
              key={status}
              onClose={() => {
                const newCallStatuses = callStatuses.filter(
                  (item) => item !== status
                )
                updateUrl('callStatuses', newCallStatuses.join(','))
                setCallStatuses(newCallStatuses)
              }}
            >
              {formatSnakeValue(status)}
            </Tag>
          ))}
          {selectedConnectedStatus.map((status) => (
            <Tag
              key={status}
              onClose={() => {
                const newConnectedStatuses = selectedConnectedStatus.filter(
                  (item) => item !== status
                )
                updateUrl('connectedStatus', newConnectedStatuses.join(','))
                setSelectedConnectedStatus(newConnectedStatuses)
              }}
            >
              {formatSnakeValue(status)}
            </Tag>
          ))}
        </div>
        <Divider orientation="vertical" className="ml-auto" />
        <Filters
          callDirections={callDirections}
          setCallDirections={setCallDirections}
          callTypes={callTypes}
          setCallTypes={setCallTypes}
          callStatuses={callStatuses}
          setCallStatuses={setCallStatuses}
          selectedConnectedStatus={selectedConnectedStatus}
          setSelectedConnectedStatus={setSelectedConnectedStatus}
        />
        <DateRangeMenu
          startDate={startDate}
          endDate={endDate}
          clearable={false}
          handleChange={(value) => {
            setStartDate(value[0])
            setEndDate(value[1])
            updateUrl('dateRange', value.join(','))
          }}
        />
      </div>

      <div className="flex-1 overflow-hidden">
        <CallTable
          connectedStatuses={selectedConnectedStatus}
          searchText={searchText}
          callDirections={callDirections}
          page={page}
          setTotalPages={setTotalPages}
          setTotalCalls={setTotalCalls}
          callStatuses={callStatuses}
          callTypes={callTypes}
          createdAt={{
            gte: startDate,
            lte: endDate,
          }}
        />
      </div>
      <PaginationArea
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
      />
    </div>
  )
}

export default ActivitiesPage
