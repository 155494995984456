/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect } from 'react'

import { NetworkStatus } from '@apollo/client'
import { CloseButton, Loader, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'

import { useQuery } from '@redwoodjs/web'

import AdminContainer from 'src/components/AdminContainer/AdminContainer'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import Empty from 'src/components/Feedback/Empty/Empty'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import { DOWNLOAD_RECORDING_URL } from 'src/graphql/activityLogs.graphql'
import { GET_CALL_TRANSCRIPTS } from 'src/graphql/callTranscripts.graphql'
import { ApplicantCallConversionStatus } from 'src/graphql/types/callActivity'
import CallConversionBadge from 'src/pages/CallAnalyticsPage/components/CallConversionBadge'
import { cn } from 'src/utils'

const Bubble = ({ transcript }) => {
  return (
    <div
      className={cn(
        'max-w-[90%] rounded p-2 text-doubleNickel-gray-700',
        (transcript.user === 'AGENT' ||
          transcript.user === 'SPEAKER_1' ||
          transcript.user === 'EMPLOYEE') &&
          'ml-auto bg-doubleNickel-brand-50',
        (transcript.user === 'HUMAN' ||
          transcript.user === 'SPEAKER_2' ||
          transcript.user === 'APPLICANT') &&
          'mr-auto bg-doubleNickel-gray-200'
      )}
    >
      {transcript.content}
    </div>
  )
}

const CallDetails = ({ activityLog, close }) => {
  const LIMIT = 20
  const page = 1
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const voiceMailTitle = activityLog.voiceMailTemplate?.title
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { data, fetchMore, networkStatus, loading } = useQuery(
    GET_CALL_TRANSCRIPTS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        activityLogId: activityLog.activityLogId,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
      onError: () => {
        toast('Unable to get transcript file', 'error')
      },
    }
  )

  const transcriptData = data?.callTranscripts.items ?? []

  const { data: recordingUrl } = useQuery(DOWNLOAD_RECORDING_URL, {
    variables: {
      activityLogId: activityLog.activityLogId,
    },
    onError: () => {
      toast('Unable to get audio file', 'error')
    },
  })

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: transcriptData.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden">
      <div className={cn('flex flex-1 flex-col gap-3 overflow-hidden')}>
        <div className="flex w-full flex-col gap-1 text-sm font-medium">
          <div className="flex flex-row items-center gap-2">
            <div>
              Call Details
              <div>
                {dayjs(activityLog.activityDate).isValid() && (
                  <div className="ml-auto text-xs text-doubleNickel-gray-500">
                    {dayjs(activityLog.activityDate).format(
                      'MM/DD/YYYY hh:mm A'
                    )}
                  </div>
                )}
              </div>
            </div>
            <CloseButton onClick={close} className="ml-auto" />
          </div>

          <AdminContainer>
            <div>
              <div className="text-xs text-doubleNickel-gray-500">
                Activity Log ID: {activityLog?.activityLogId}
              </div>
              {activityLog?.blandCallSid && (
                <div className="text-xs text-doubleNickel-gray-500">
                  BlandAI Call ID:{' '}
                  <a
                    href={`https://app.bland.ai/dashboard/call-logs/${activityLog?.blandCallSid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {activityLog?.blandCallSid}
                  </a>
                </div>
              )}
            </div>
          </AdminContainer>
          <div className="mt-2 flex w-full flex-row items-center gap-1 overflow-auto text-xs">
            {isLeftVoiceMail && voiceMailTitle && (
              <div className="rounded bg-doubleNickel-error-100 p-2 py-1 text-doubleNickel-gray-800">
                {voiceMailTitle}
              </div>
            )}
            {activityLog?.connectedStatus && (
              <CallConversionBadge
                status={ApplicantCallConversionStatus.CONNECTED}
                connectedStatus={activityLog.connectedStatus}
              />
            )}
            {activityLog?.discussionTopics?.length > 0 &&
              activityLog?.discussionTopics?.map((topic) => (
                <Tooltip
                  label={`Discussion Topics`}
                  key={topic}
                  withArrow
                  offset={8}
                >
                  <div>
                    <Tag size="xs" showCloseButton={false}>
                      {topic === 'payment' ? 'compensation' : topic}
                    </Tag>
                  </div>
                </Tooltip>
              ))}
          </div>
        </div>

        <div className={cn('flex flex-1 flex-col gap-4 overflow-auto text-sm')}>
          {activityLog?.transcriptSummary &&
            activityLog?.transcriptSummary !== 'NO_ANSWER' && (
              <div className="text-doubleNickel-gray-700">
                Summary: {activityLog?.transcriptSummary}
              </div>
            )}
          {transcriptData?.length === 0 && !loading ? (
            <Empty
              title={
                activityLog?.transcriptFileUrl
                  ? 'We are unable to display this transcript'
                  : 'No transcript available'
              }
              subtitle={
                activityLog?.transcriptFileUrl
                  ? 'You can download it for viewing'
                  : 'Please try another call'
              }
            />
          ) : (
            <div className="flex flex-col gap-4">
              {transcriptData.map((transcript) => (
                <Bubble
                  key={transcript.callTranscriptId}
                  transcript={transcript}
                />
              ))}
              {networkStatus === NetworkStatus.fetchMore && (
                <Loader size="sm" className="my-4 flex w-full justify-center" />
              )}
              {transcriptData.length < data?.callTranscripts.totalCount &&
                !loading && (
                  <div ref={ref} className="opacity-0">
                    Inview placeholder
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      {recordingUrl?.getRecordingDownloadUrl?.url && (
        <div className="flex items-center justify-center">
          <audio
            src={recordingUrl?.getRecordingDownloadUrl?.url || ''}
            controls
            className="h-10 w-full rounded bg-[#F1F3F4]"
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  )
}

export default CallDetails
