import { useContext } from 'react'

import { ActivityLog } from 'types/graphql'

import { RecruitersContext } from 'src/context/RecruitersContext'
import { formatDate } from 'src/lib/formatters'
import { replaceRecruitersIdWithName } from 'src/lib/recruiters.utils'

interface NoteLogCardProps {
  activityLog: ActivityLog
}

const NoteLog = ({ activityLog }: NoteLogCardProps) => {
  const recruiters = useContext(RecruitersContext)
  return (
    <div
      className={`flex flex-col-reverse items-end gap-2 border-doubleNickel-gray-300 p-1 py-4`}
    >
      <div className="flex flex-1 flex-row hyphens-auto text-sm text-doubleNickel-gray-900 [overflow-wrap:anywhere]">
        {replaceRecruitersIdWithName(activityLog.content, recruiters)}
      </div>
      <div className={`flex flex-1 flex-row gap-2 text-xs`}>
        <div className="font-medium text-doubleNickel-gray-600">
          {activityLog.employee &&
            `${activityLog.employee.firstName} ${activityLog.employee.lastName}`}{' '}
        </div>
        <div className="text-doubleNickel-gray-400">
          {formatDate(activityLog.createdAt)}
        </div>
      </div>
    </div>
  )
}

export default NoteLog
