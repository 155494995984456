import { useRef } from 'react'

import { DateInput as MantineDateInput, DateInputProps } from '@mantine/dates'
import dayjs from 'dayjs'

const DateInput = ({ editing = true, ...props }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  //Disabled styles:
  const inputStyles = `[&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-900`

  // Custom date parser to handle MM/DD/YYYY format
  const dateParser: DateInputProps['dateParser'] = (input) => {
    const formattedDate = dayjs(input, 'MMDDYYYY').toDate()
    return formattedDate
  }

  // Function to format input as MM/DD/YYYY while typing
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value.replace(/\D/g, '') // Remove non-numeric characters

    if (value.length > 8) value = value.slice(0, 8) // Restrict to 8 characters (MMDDYYYY)

    const formattedValue = value.replace(
      /^(\d{2})(\d{0,2})(\d{0,4})$/,
      (_, m, d, y) => {
        let result = m
        if (d) result += '/' + d
        if (y) result += '/' + y
        return result
      }
    )

    event.currentTarget.value = formattedValue // Update the visible input value
  }

  return (
    <MantineDateInput
      dateParser={dateParser}
      valueFormat="MM/DD/YYYY"
      placeholder="MM/DD/YYYY"
      minDate={new Date('1900-01-01')}
      maxDate={new Date('2100-01-01')}
      variant={editing ? 'default' : 'unstyled'}
      disabled={!editing}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        input: inputStyles,
      }}
      ref={inputRef}
      onInput={handleInput} // Real-time formatting without modifying onChange
      {...props}
    />
  )
}

export default DateInput
