import { ActivityLog, Applicant } from 'types/graphql'

import AttachmentsMenu from 'src/components/Overlays/AttachmentsMenu/AttachmentsMenu'
import { SMSDirection } from 'src/graphql/types/activityLogs'
import { formatDate } from 'src/lib/formatters'

interface SMSLogCardProps {
  activityLog: ActivityLog
  applicant?: Applicant
}

const SMSLog = ({ activityLog, applicant }: SMSLogCardProps) => {
  let displayName = ''

  if (
    activityLog.smsDirection === SMSDirection.OUTBOUND &&
    activityLog.employee
  ) {
    displayName = `${activityLog.employee.firstName} ${activityLog.employee.lastName}`
  } else if (activityLog.smsDirection === SMSDirection.INBOUND && applicant) {
    displayName = `${applicant.firstName} ${applicant.lastName}`
  }

  return (
    <div
      className={`mb-2 flex max-w-[75%] flex-col gap-1 ${
        activityLog?.smsDirection === SMSDirection.OUTBOUND
          ? 'ml-auto'
          : 'mr-auto'
      }`}
    >
      <div
        className={`flex w-full flex-row ${
          activityLog?.smsDirection === SMSDirection.OUTBOUND
            ? 'justify-end'
            : 'justify-start'
        } gap-2 text-xs text-doubleNickel-gray-600`}
      >
        <div className="font-medium">{displayName}</div>
        <div className="text-doubleNickel-gray-400">
          {formatDate(activityLog.createdAt)}
        </div>
      </div>
      <div
        className={`flex flex-col gap-2 rounded-t-lg p-3 ${
          activityLog?.smsDirection === SMSDirection.OUTBOUND
            ? 'rounded-bl-xl  bg-doubleNickel-brand-25'
            : 'rounded-br-xl  bg-doubleNickel-gray-50'
        }`}
      >
        <div
          className="w-full overflow-hidden hyphens-auto whitespace-pre-wrap text-sm text-doubleNickel-gray-900 [overflow-wrap:anywhere]
"
        >
          {activityLog.content}
        </div>
        {activityLog?.documents && activityLog?.documents.length > 0 && (
          <div
            className={`${
              activityLog?.smsDirection === SMSDirection.OUTBOUND
                ? 'ml-auto'
                : 'mr-auto'
            }`}
          >
            <AttachmentsMenu
              attachments={activityLog.documents}
              applicantId={applicant.applicantId}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default SMSLog
