import { ApplicantDrawerContextProvider } from './ApplicantDrawerContext'
import { ApplicantListContextProvider } from './ApplicantListContext'
import { CallDrawerContextProvider } from './CallDrawerContext'
import { CurrentCompanyContextProvider } from './CurrentCompanyContext'
import { RecruitersContextProvider } from './RecruitersContext'

const DashboardContexts = ({ children }) => {
  return (
    <RecruitersContextProvider>
      <CurrentCompanyContextProvider>
        <ApplicantDrawerContextProvider>
          <CallDrawerContextProvider>
            <ApplicantListContextProvider>
              {children}
            </ApplicantListContextProvider>
          </CallDrawerContextProvider>
        </ApplicantDrawerContextProvider>
      </CurrentCompanyContextProvider>
    </RecruitersContextProvider>
  )
}

export default DashboardContexts
