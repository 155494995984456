import { Indicator, Tooltip, UnstyledButton } from '@mantine/core'

import { NavLink, useLocation } from '@redwoodjs/router'

interface SidebarLinkProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
  label: string
  route: string
  notification?: boolean
  expanded: boolean
}

const SidebarLink = ({
  icon: Icon,
  label,
  route,
  notification = false,
  expanded,
}: SidebarLinkProps) => {
  const location = useLocation()
  const isActive = route.split('/')[1] === location.pathname.split('/')[1]

  return (
    <Tooltip
      key={label}
      label={label}
      position="right"
      transitionProps={{ duration: 0 }}
    >
      <Indicator
        color="orange"
        disabled={!notification}
        size={5}
        position="middle-end"
      >
        <NavLink
          to={route}
          className={`text-based flex h-9 flex-row items-center text-sm font-medium text-doubleNickel-gray-600 ${
            expanded
              ? 'min-w-[12rem] gap-3 transition-all duration-500'
              : 'min-w-[2rem] transition-all  duration-500'
          } px-2`}
          activeClassName="rounded-md bg-white"
        >
          <Icon
            className={`h-4 w-4 fill-none ${
              isActive
                ? 'stroke-doubleNickel-brand-600'
                : 'stroke-doubleNickel-gray-600'
            }`}
          />
          {expanded && (
            <div
              className={`  ${
                isActive
                  ? 'text-doubleNickel-brand-600'
                  : 'text-doubleNickel-gray-600'
              }`}
            >
              {label}
            </div>
          )}
        </NavLink>
      </Indicator>
    </Tooltip>
  )
}

export default SidebarLink
