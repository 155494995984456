export const GET_ACTIVITY_LOGS = gql`
  query GetActivityLogs(
    $applicantId: String!
    $filters: ActivityLogFiltersInput
    $pageInfo: PageInfoInput
  ) {
    activityLogs(
      applicantId: $applicantId
      filters: $filters
      pageInfo: $pageInfo
    ) {
      totalCount
      items {
        activityLogId
        createdAt
        recordingSid
        recordingUrl
        updatedAt
        activityDate
        connectedStatus
        callType
        documents {
          documentId
          createdAt
          updatedAt
          status
          userStatus
          uploadStatus
          fileType
          fileName
          uploadUrl
        }
        type
        title
        content
        smsDirection
        callDirection
        callStatus
        startCallDate
        endCallDate
        callDuration
        transcriptFileUrl
        transcriptSummary
        labels
        employee {
          employeeId
          firstName
          lastName
        }
        voiceMailTemplate {
          voiceMailTemplateId
          title
        }
      }
    }
  }
`

export const GET_ACTIVITY_LOG = gql`
  query GetActivityLog($id: String!) {
    activityLog(id: $id) {
      activityLogId
      createdAt
      recordingSid
      recordingUrl
      updatedAt
      activityDate
      connectedStatus
      callType
      from
      to
      documents {
        documentId
        createdAt
        updatedAt
        status
        userStatus
        uploadStatus
        fileType
        fileName
        uploadUrl
      }
      type
      title
      content
      smsDirection
      callDirection
      callStatus
      startCallDate
      endCallDate
      callDuration
      transcriptFileUrl
      transcriptSummary
      blandCallSid
      discussionTopics
      labels
      applicant {
        applicantId
        firstName
        middleName
        lastName
      }
      employee {
        employeeId
        firstName
        lastName
      }
      voiceMailTemplate {
        voiceMailTemplateId
        title
      }
    }
  }
`

export const GET_CALLS = gql`
  query GetCalls($filters: CallFiltersInput, $pageInfo: PageInfoInput) {
    getCalls(filters: $filters, pageInfo: $pageInfo) {
      totalCount
      items {
        activityLogId
        createdAt
        recordingSid
        recordingUrl
        updatedAt
        activityDate
        callType
        from
        to
        documents {
          documentId
          createdAt
          updatedAt
          status
          userStatus
          uploadStatus
          fileType
          fileName
          uploadUrl
        }
        type
        title
        content
        smsDirection
        callDirection
        callStatus
        connectedStatus
        startCallDate
        endCallDate
        callDuration
        transcriptFileUrl
        transcriptSummary
        labels
        applicant {
          applicantId
          firstName
          middleName
          lastName
        }
        employee {
          employeeId
          firstName
          lastName
        }
        voiceMailTemplate {
          voiceMailTemplateId
          title
        }
      }
    }
  }
`

export const GET_ACTIVE_CALL = gql`
  query GetActiveCall {
    getActiveCall {
      activityLogId
      createdAt
      updatedAt
      activityDate
      type
      title
      content
      smsDirection
      callDirection
      callStatus
      employee {
        employeeId
        firstName
        lastName
      }
    }
  }
`

export const DOWNLOAD_RECORDING_URL = gql`
  query GetRecordingDownloadUrl($activityLogId: String!) {
    getRecordingDownloadUrl(activityLogId: $activityLogId) {
      url
      fileName
    }
  }
`

export const CREATE_ACTIVITY_LOG = gql`
  mutation CreateActivityLog($input: CreateActivityLogInput!) {
    createActivityLog(input: $input) {
      activityLogId
    }
  }
`

export const BULK_SMS = gql`
  mutation BulkSms($applicants: [String!]!, $textTemplateId: String!) {
    bulkSms(applicants: $applicants, textTemplateId: $textTemplateId) {
      applicants {
        applicantId
        success
        errorMsg
      }
    }
  }
`

export const BULK_CALL = gql`
  mutation BulkCall($applicants: [String!]!, $activityDate: DateTime!) {
    bulkCall(applicants: $applicants, activityDate: $activityDate) {
      applicants {
        applicantId
        success
        errorMsg
      }
    }
  }
`

export const DOWNLOAD_TRANSCRIPT_URL = gql`
  query GetTranscriptDownloadUrl($activityLogId: String!) {
    getTranscriptDownloadUrl(activityLogId: $activityLogId) {
      url
      fileName
    }
  }
`
