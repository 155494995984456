const Row = ({
  children,
  value,
  tooltipText,
}: {
  children?: any
  value: any
  tooltipText?: string
}) => {
  return (
    <div className="flex flex-1 flex-col text-xs">
      <div className="flex-1 text-xs font-medium text-doubleNickel-gray-500">
        {tooltipText}
      </div>
      <div className="flex flex-1 flex-row items-center gap-1 text-sm text-doubleNickel-gray-900">
        {value}
        <>{children}</>
      </div>
    </div>
  )
}

export default Row
