import { useRef, useState } from 'react'

import { CloseButton, Divider, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
import useUploadDocument from 'src/hooks/useUploadDocument/useUploadDocument'

import SMSInputMenu from './components/SMSInputMenu'

const MAX_SMS_LENGTH = 500

const SMSInput = ({ sendSMS, applicantId }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const textTemplatesRef = useRef(null)
  const { handleCreateDocuments } = useUploadDocument(
    ['GET_DOCUMENTS'],
    applicantId
  )

  const SMSInputSchema = z.object({
    content: z
      .string()
      .min(1, { message: 'Enter at least 1 character.' })
      .max(MAX_SMS_LENGTH, `Characters limit is ${MAX_SMS_LENGTH}.`),
  })

  const form = useForm({
    initialValues: {
      content: '',
    },
    validateInputOnChange: true,
    validate: zodResolver(SMSInputSchema),
  })

  const onFormSubmit = (values) => {
    const onDocumentsCreated = (documents) => {
      sendSMS({
        type: ActivityLogType.SMS,
        documents: [...documents],
        ...values,
      })
    }

    if (selectedFiles.length > 0) {
      handleCreateDocuments(selectedFiles, onDocumentsCreated)
    } else {
      sendSMS({
        type: ActivityLogType.SMS,
        ...values,
      })
    }
    form.reset()
    textTemplatesRef?.current?.resetTemplate()
    setSelectedFiles([])
  }

  return (
    <div>
      <form
        onSubmit={form.onSubmit((values) => onFormSubmit(values))}
        className="flex flex-row items-center gap-2"
      >
        <div className="flex flex-1 flex-col gap-1 rounded-lg border px-2">
          <Textarea
            variant="unstyled"
            placeholder="Type message..."
            autosize={true}
            rightSection={
              <SMSInputMenu
                applicantId={applicantId}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                onTemplateSelect={(content) =>
                  form.setFieldValue('content', content)
                }
              />
            }
            maxRows={4}
            className="flex-1"
            withAsterisk
            {...form.getInputProps('content')}
          />
          {selectedFiles.length > 0 && (
            <>
              <Divider />
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center justify-between rounded-sm text-xs font-medium text-doubleNickel-gray-900 hover:bg-doubleNickel-brand-25"
                >
                  {file.name}
                  <CloseButton
                    size="xs"
                    onClick={() => {
                      setSelectedFiles(
                        selectedFiles.filter(
                          (selectedFile) => selectedFile.name !== file.name
                        )
                      )
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <Button
          text="Send"
          type="submit"
          disabled={!form.isValid()}
          className={`self-end ${
            Object.keys(form?.errors).length ? 'mb-5' : ''
          }`}
        />
      </form>
    </div>
  )
}

export default SMSInput
