import { Box } from '@mantine/core'
import { Step } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { StepStatus } from 'src/graphql/types/steps'
import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import IconCircle from 'src/icons/IconCircle'
import { formatSnakeValue, formatStepType } from 'src/lib/formatters'

interface StepItemProps {
  step: Step
  applicantId?: string
}
const StepItem = ({ step, applicantId }: StepItemProps) => {
  const statusColor = (status: StepStatus) => {
    switch (status) {
      case StepStatus.NOT_STARTED:
        return 'text-doubleNickel-gray-500'
      case StepStatus.IN_PROGRESS:
        return 'text-doubleNickel-brand-500'
      case StepStatus.PENDING_REVIEW:
        return 'text-doubleNickel-warning-500'
      case StepStatus.COMPLETED:
        return 'text-doubleNickel-success-500'
    }
  }

  const renderIcon = (status: StepStatus) => {
    switch (status) {
      case StepStatus.NOT_STARTED:
        return (
          <IconCircle className="h-3 w-3 fill-none stroke-doubleNickel-gray-500" />
        )
      case StepStatus.IN_PROGRESS:
        return (
          <IconCircle className="h-3 w-3 fill-none stroke-doubleNickel-brand-500" />
        )
      case StepStatus.PENDING_REVIEW:
        return (
          <IconCircle className="h-3 w-3 fill-none stroke-doubleNickel-warning-500" />
        )
      case StepStatus.COMPLETED:
        return (
          <IconCheckmarkCircle className="h-3 w-3 fill-none stroke-doubleNickel-success-500" />
        )
    }
  }

  return (
    <Box
      className="flex w-full flex-row items-center gap-2 text-xs hover:cursor-pointer"
      onClick={() =>
        navigate(
          routes.applicantDetails({
            id: applicantId,
            tab: 'documents',
            dqf: step.type,
          })
        )
      }
    >
      {/* <div className="place-self-center"></div> */}
      <div className="flex w-full flex-col gap-2 ">
        <div className="flex flex-row items-center justify-between text-ellipsis text-nowrap font-medium text-doubleNickel-gray-600">
          <div className="flex flex-row items-center gap-2 hover:underline">
            {/* {renderIcon(step.status)} */}
            {formatStepType(step.type)}
          </div>
          <div className={statusColor(step.status)}>
            {formatSnakeValue(step.status)}
          </div>
        </div>
        <div className="text-doubleNickel-gray-600">
          {step.documents
            .filter(
              (doc) =>
                doc.status !== 'ARCHIVED' && doc.userStatus === 'PENDING_REVIEW'
            )
            .map((doc) => (
              <div
                key={doc.documentId}
                className="flex flex-row items-center justify-between  gap-1 text-nowrap rounded-lg py-2 pl-6 text-doubleNickel-gray-900 "
              >
                <div className="flex flex-row items-center gap-2 text-doubleNickel-gray-500 hover:cursor-pointer hover:underline">
                  {doc.fileName}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Box>
  )
}

export default StepItem
