const IconHorizontalBarChart = (props) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.5V5.1C15 4.53995 15 4.25992 14.891 4.04601C14.7951 3.85785 14.6422 3.70487 14.454 3.60899C14.2401 3.5 13.9601 3.5 13.4 3.5H1M11 13.5V16.9C11 17.4601 11 17.7401 10.891 17.954C10.7951 18.1422 10.6422 18.2951 10.454 18.391C10.2401 18.5 9.96005 18.5 9.4 18.5H1M1 1L1 21M1 13.5L17.4 13.5C17.9601 13.5 18.2401 13.5 18.454 13.391C18.6422 13.2951 18.7951 13.1422 18.891 12.954C19 12.7401 19 12.4601 19 11.9V10.1C19 9.53995 19 9.25992 18.891 9.04601C18.7951 8.85785 18.6422 8.70487 18.454 8.60899C18.2401 8.5 17.9601 8.5 17.4 8.5L1 8.5L1 13.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconHorizontalBarChart
