/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ActionIcon, Box, Menu, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { ActivityLog } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import {
  DOWNLOAD_RECORDING_URL,
  DOWNLOAD_TRANSCRIPT_URL,
} from 'src/graphql/activityLogs.graphql'
import { ApplicantCallConversionStatus } from 'src/graphql/types/callActivity'
import IconThreeDots from 'src/icons/IconThreeDots'
import { buildFullName, formatSnakeValue } from 'src/lib/formatters'
import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import CallConversionBadge from 'src/pages/CallAnalyticsPage/components/CallConversionBadge'
import {
  getStatusColor,
  getStatusLabel,
} from 'src/pages/CallAnalyticsPage/utils'
import { cn } from 'src/utils'
import { downloadFile } from 'src/utils/downloadFile'

const DropdownMenu = ({ activityLog }) => {
  const [downloadRecording, { called, data: downloadResponse }] = useLazyQuery(
    DOWNLOAD_RECORDING_URL,
    {
      onCompleted: () => {
        if (called && downloadResponse?.getRecordingDownloadUrl?.url) {
          const { url, fileName } = downloadResponse.getRecordingDownloadUrl
          downloadFile({ url, fileName })
        }
      },
      onError: () => {
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )

  const [
    downloadTranscript,
    { called: calledTranscript, data: downloadTranscriptResponse },
  ] = useLazyQuery(DOWNLOAD_TRANSCRIPT_URL, {
    onCompleted: () => {
      if (
        calledTranscript &&
        downloadTranscriptResponse?.getTranscriptDownloadUrl?.url
      ) {
        const { url, fileName } =
          downloadTranscriptResponse.getTranscriptDownloadUrl
        downloadFile({ url, fileName })
      }
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const handleRecording = useCallback(() => {
    downloadRecording({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadRecording])

  const handleTranscript = useCallback(() => {
    downloadTranscript({
      variables: {
        activityLogId: activityLog.activityLogId,
      },
    })
  }, [activityLog, downloadTranscript])

  return (
    <Menu shadow="md" position="bottom-end">
      <Menu.Target>
        <ActionIcon variant="subtle" onClick={(e) => e.stopPropagation()}>
          <IconThreeDots className="h-4 fill-doubleNickel-white stroke-doubleNickel-gray-600" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown className="w-40">
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.recordingSid}
          onClick={(e) => {
            e.stopPropagation()
            handleRecording()
          }}
        >
          Download audio recording
        </Menu.Item>
        <Menu.Item
          className="text-doubleNickel-gray-700"
          disabled={!activityLog.transcriptFileUrl}
          onClick={(e) => {
            e.stopPropagation()
            handleTranscript()
          }}
        >
          Download transcript
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

interface CallLogCardProps {
  activityLog: ActivityLog
  handleSelect: (activityLog: ActivityLog) => void
  isSelected: boolean
}

const CallRow = ({
  activityLog,
  handleSelect,
  isSelected,
}: CallLogCardProps) => {
  const { handleOpen } = useApplicantDrawerContext()
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isVirtualCall = activityLog.callType === 'BLANDAI'
  const label = isOutboundCall ? 'Outbound' : 'Inbound'
  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callStatus = activityLog?.callStatus
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const isManualRegistered = activityLog.callStatus === null
  const voiceMailTitle = activityLog.voiceMailTemplate?.title
  const fromNumber = activityLog?.from
    ? formatInternationalPhoneNumber(activityLog?.from)
    : 'Unknown'
  const fromApplicant = activityLog?.applicant
    ? buildFullName(activityLog.applicant)
    : fromNumber
  const toNumber = activityLog?.to
    ? formatInternationalPhoneNumber(activityLog?.to)
    : 'Unknown'
  const toApplicant = activityLog?.applicant
    ? buildFullName(activityLog.applicant)
    : toNumber
  const employeeContact = activityLog?.employee
    ? buildFullName(activityLog.employee)
    : isOutboundCall
    ? fromNumber
    : toNumber
  const recruiter = isVirtualCall ? 'Voice Agent' : employeeContact
  const from = isOutboundCall ? recruiter : fromApplicant
  const to = isOutboundCall ? toApplicant : recruiter

  const handleTranscripts = () => {
    handleSelect(activityLog)
  }

  return (
    <Box
      className={cn(
        'border-bg-doubleNickel-gray-600 grid min-h-10 cursor-pointer grid-cols-9 px-2 py-1 text-xs text-doubleNickel-gray-500 hover:bg-doubleNickel-gray-100',
        isSelected && 'rounded bg-doubleNickel-brand-25'
      )}
      onClick={handleTranscripts}
    >
      <div className="col-span-1 my-auto">
        {dayjs(activityLog.activityDate).isValid()
          ? dayjs(activityLog.activityDate).format('MM/DD/YYYY')
          : 'Invalid Date'}
      </div>
      <div className="col-span-1 my-auto">
        {dayjs(activityLog.activityDate).isValid()
          ? dayjs(activityLog.activityDate).format('hh:mm A')
          : 'Invalid Date'}
      </div>
      <Tooltip label={from} position="top">
        <Box
          className={cn(
            'col-span-1 my-auto w-full overflow-hidden text-ellipsis',
            isVirtualCall && 'text-doubleNickel-brand-500'
          )}
        >
          <span
            className={cn(
              activityLog.applicant?.applicantId &&
                !isOutboundCall &&
                'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline'
            )}
            onClick={(e) => {
              if (!isOutboundCall && activityLog.applicant?.applicantId) {
                e.stopPropagation()
                //If the user holds down the meta key (command on mac, control on windows) and clicks on the link, it will open in a new tab
                if (e.metaKey) {
                  window.open(
                    routes.applicantDetails({
                      id: activityLog.applicant.applicantId,
                      tab: 'application',
                    }),
                    '_blank' // Opens in a new tab
                  )
                } else {
                  handleOpen(activityLog.applicant.applicantId)
                }
              }
            }}
          >
            {from}
          </span>
        </Box>
      </Tooltip>
      <Tooltip label={to} position="top">
        <Box
          className={cn(
            'col-span-1 my-auto w-full overflow-hidden text-ellipsis '
          )}
        >
          <span
            className={cn(
              activityLog.applicant?.applicantId &&
                isOutboundCall &&
                'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline'
            )}
            onClick={(e) => {
              if (isOutboundCall && activityLog.applicant?.applicantId) {
                e.stopPropagation()
                //If the user holds down the meta key (command on mac, control on windows) and clicks on the link, it will open in a new tab
                if (e.metaKey) {
                  window.open(
                    routes.applicantDetails({
                      id: activityLog.applicant.applicantId,
                      tab: 'application',
                    }),
                    '_blank' // Opens in a new tab
                  )
                } else {
                  handleOpen(activityLog.applicant.applicantId)
                }
              }
            }}
          >
            {to}
          </span>
        </Box>
      </Tooltip>
      <div className="my-auto">
        {isOutboundCall ? '→' : '←'} {label}
      </div>{' '}
      <div
        className={cn(
          isMissedCall && 'font-medium text-doubleNickel-error-500',
          activityLog?.callStatus === CallStatus.COMPLETED &&
            'font-medium text-doubleNickel-success-500',
          'col-span-1 my-auto'
        )}
      >
        {isManualRegistered ? 'Manual' : formatSnakeValue(callStatus)}
      </div>
      <div className="col-span-1 my-auto">
        {!isNaN(callInMinutes)
          ? `${callInMinutes}:${remindingSeconds.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} min`
          : '0:00 min'}
      </div>
      <div className="col-span-2 my-auto flex flex-row items-center justify-end gap-1 overflow-hidden">
        <div className="flex flex-row items-center gap-1 overflow-auto">
          {isLeftVoiceMail && voiceMailTitle && (
            <div className="rounded bg-doubleNickel-error-100 p-2 py-1 text-doubleNickel-gray-800">
              {voiceMailTitle}
            </div>
          )}
          {activityLog?.connectedStatus && (
            <CallConversionBadge
              status={ApplicantCallConversionStatus.CONNECTED}
              connectedStatus={activityLog.connectedStatus}
            />
          )}
        </div>
        <DropdownMenu activityLog={activityLog} />
      </div>
    </Box>
  )
}

export default CallRow
