import AnvilEmbedFrame from '@anvilco/anvil-embed-frame'

export const Signature = ({
  signatureUrl,
  onSignatureComplete,
  onSignatureError,
}) => {
  return (
    <div className="m-auto flex h-[calc(100vh-200px)] w-[95vw]">
      <AnvilEmbedFrame
        className="flex-1"
        iframeURL={signatureUrl}
        onEvent={(event) => {
          if (event.action === 'signerComplete') {
            onSignatureComplete(event)
          }
          if (event.action === 'signerError') {
            onSignatureError(event)
          }
        }}
        scroll="smooth"
      />
    </div>
  )
}
