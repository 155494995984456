import { Modal, ActionIcon, Container } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import { PDFViewer } from 'src/components/DataDisplay/PDFViewer/PDFViewer'
import IconDownload from 'src/icons/IconDownload'
import IconLeftArrow from 'src/icons/IconLeftArrow'
import IconPrint from 'src/icons/IconPrint'

export interface PDFPreviewModalHeaderProps {
  fileName: string
  onBack: () => void
  onPrint?: () => void
  onDownload?: () => void
}

const PDFPreviewModalHeader = ({
  fileName,
  onBack,
  onPrint,
  onDownload,
}: PDFPreviewModalHeaderProps) => (
  <div className="flex w-full">
    {' '}
    {/* Ensure full width */}
    <div className="flex w-full flex-row justify-between gap-4">
      {' '}
      {/* Adjust alignment */}
      <div className="flex flex-row gap-4">
        <ActionIcon
          variant="subtle"
          className="bg-doubleNickel-white"
          onClick={onBack}
        >
          <IconLeftArrow className="fill-none stroke-doubleNickel-gray-600" />
        </ActionIcon>
        <div className="text-lg font-bold text-doubleNickel-gray-600">
          {fileName}
        </div>
      </div>
      <div className="flex gap-4">
        {' '}
        {/* This now aligns to the right */}
        {onPrint && (
          <Button
            variant="outline"
            text="Print"
            lefticon={<IconPrint />}
            onClick={onPrint}
          />
        )}
        {onDownload && (
          <Button
            variant="outline"
            text="Download"
            lefticon={<IconDownload />}
            onClick={onDownload}
          />
        )}
      </div>
    </div>
  </div>
)

export interface PDFPreviewModalProps {
  fileName: string
  url: string
  isOpen: boolean
  onClose: () => void
  onBack: () => void
  onPrint?: () => void
  onDownload?: () => void
}

export const PDFPreviewModal = ({
  fileName,
  url,
  isOpen,
  onClose,
  onBack,
  onPrint,
  onDownload,
}: PDFPreviewModalProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      fullScreen={true}
      centered
      withCloseButton={false}
    >
      <div className="flex h-[95vh] flex-col gap-4 ">
        <PDFPreviewModalHeader
          fileName={fileName}
          onBack={onBack}
          onPrint={onPrint}
          onDownload={onDownload}
        />
        {url && <PDFViewer url={url} />}
      </div>
    </Modal>
  )
}
