import { ActivityLogsResult, NotificationsResult } from 'types/graphql'

export const graphQLClientConfig = {
  cacheConfig: {
    typePolicies: {
      Query: {
        fields: {
          notifications: {
            keyArgs: ['notificationId'],
            merge(existing = {} as NotificationsResult, incoming) {
              const existingItems = existing.items || []
              const incomingItems = incoming.items || []

              // Create a set of unique IDs from existing items
              const existingIds = new Set(
                existingItems.map((item) => item.notificationId)
              )

              // Only add incoming items that don't already exist
              const mergedItems = [
                ...existingItems,
                ...incomingItems.filter(
                  (item) => !existingIds.has(item.notificationId)
                ),
              ]

              // Sort merged items by createdAt in descending order
              mergedItems.sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )

              return { ...incoming, items: mergedItems }
            },
          },
          activityLogs: {
            keyArgs: ['applicantId', 'filters'],
            merge(existing = {} as ActivityLogsResult, incoming) {
              const existingItems = existing.items || []
              const incomingItems = incoming.items || []

              // Create a set of unique IDs from existing items
              const existingIds = new Set(
                existingItems.map((item) => item.activityLogId)
              )

              // Only add incoming items that don't already exist
              const mergedItems = [
                ...existingItems,
                ...incomingItems.filter(
                  (item) => !existingIds.has(item.activityLogId)
                ),
              ]

              // Sort merged items by createdAt in descending order
              mergedItems.sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )

              return { ...incoming, items: mergedItems }
            },
          },
          callTranscripts: {
            keyArgs: ['activityLogId'],
            merge(existing, incoming) {
              const existingItems = existing?.items || []
              const incomingItems = incoming.items || []

              const existingIds = new Set(
                existingItems.map((item) => item.callTranscriptId)
              )

              // Only add incoming items that don't already exist
              const mergedItems = [
                ...existingItems,
                ...incomingItems.filter(
                  (item) => !existingIds.has(item.callTranscriptId)
                ),
              ]

              // Sort merged items by createdAt in descending order
              mergedItems.sort(
                (a, b) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime()
              )

              return {
                ...incoming,
                items: mergedItems,
              }
            },
          },
          getBookings: {
            keyArgs: ['bookingId', 'filters', 'isInfiniteScroll'],
            merge(existing, incoming, { args }) {
              const isInfiniteScroll = args?.isInfiniteScroll || false

              // If infinite scroll is disabled, simply replace the items
              if (!isInfiniteScroll) {
                return {
                  ...incoming,
                  items: incoming.items || [],
                }
              }

              const existingItems = existing?.items || []
              const incomingItems = incoming.items || []

              const existingIds = new Set(
                existingItems.map((item) => item.bookingId)
              )

              // Only add incoming items that don't already exist
              const mergedItems = [
                ...existingItems,
                ...incomingItems.filter(
                  (item) => !existingIds.has(item.bookingId)
                ),
              ]

              return {
                ...incoming,
                items: mergedItems,
              }
            },
          },
          applicants: {
            keyArgs: ['filters'],
            merge(existing, incoming) {
              const existingItems = existing?.items || []
              const incomingItems = incoming.items || []

              // Create a set of unique IDs from existing items
              const existingIds = new Set(
                existingItems.map((item) => item.applicantId)
              )

              // Only add incoming items that don't already exist
              const mergedItems = [
                ...existingItems,
                ...incomingItems.filter(
                  (item) => !existingIds.has(item.applicantId)
                ),
              ]

              return {
                ...incoming,
                items: mergedItems,
              }
            },
          },
        },
      },
    },
  },
}
