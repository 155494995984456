import { useState } from 'react'

import { ActionIcon, Badge, Box, Menu } from '@mantine/core'

import SimpleButton from 'src/components/Buttons/Button/SimpleButton'
import IconCheckmark from 'src/icons/IconCheckmark'
import IconChevronLeft from 'src/icons/IconChevronLeft'
import IconFilter from 'src/icons/IconFilter'
import { formatSnakeValue } from 'src/lib/formatters'

import { updateUrl } from '../../../pages/CallAnalyticsPage/utils'

const FilterMenu = ({ filters }) => {
  const [selectedSubmenu, setSelectedSubmenu] = useState('')

  const getTotalSelectedCount = () => {
    return filters.reduce(
      (total, filter) => total + (filter.selected?.length || 0),
      0
    )
  }

  const getDisplayValue = (filter, value) => {
    if (filter.formatValue) {
      return filter.formatValue(value)
    }
    if (filter.options?.find) {
      return filter.options.find((opt) => opt.value === value)?.label
    }
    return formatSnakeValue(value)
  }

  const handleClear = (filter) => {
    filter.onChange([])
    updateUrl(filter.urlKey, '')
  }

  const handleClearAll = () => {
    filters.forEach((filter) => {
      filter.onChange([])
      updateUrl(filter.urlKey, '')
    })
  }

  const menuContents = () => {
    if (selectedSubmenu) {
      const activeFilter = filters.find(
        (f) => f.title.toLowerCase() === selectedSubmenu
      )
      if (!activeFilter) return null

      return (
        <>
          {activeFilter.options
            .slice()
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((option) => (
              <Box
                key={option.value}
                className="flex flex-row items-center justify-between rounded p-2 px-2 text-xs hover:bg-doubleNickel-gray-100"
                onClick={() => {
                  const newSelected = activeFilter.selected.includes(
                    option.value
                  )
                    ? activeFilter.selected.filter(
                        (item) => item !== option.value
                      )
                    : [...activeFilter.selected, option.value]
                  activeFilter.onChange(newSelected)
                }}
              >
                {option.label}
                {activeFilter.selected.includes(option.value) && (
                  <IconCheckmark className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
                )}
              </Box>
            ))}
        </>
      )
    }

    return (
      <>
        {filters
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((filter) => (
            <Menu.Item
              key={filter.title}
              rightSection={
                <IconChevronLeft className="rotate-180 fill-none stroke-doubleNickel-gray-400" />
              }
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setSelectedSubmenu(filter.title.toLowerCase())
              }}
            >
              <Box className="flex flex-row items-center justify-between py-1 text-xs font-medium text-doubleNickel-gray-800">
                {filter.title}
                <div className="font-normal text-doubleNickel-gray-500">
                  {filter.selected?.length > 0 &&
                    getDisplayValue(filter, filter.selected[0])}
                  {filter.selected?.length > 1 &&
                    `, ${filter.selected.length - 1}+`}
                </div>
              </Box>
            </Menu.Item>
          ))}
      </>
    )
  }

  return (
    <Menu
      shadow="md"
      width={275}
      position="bottom-end"
      withArrow
      arrowPosition="center"
      closeOnItemClick={false}
    >
      <Menu.Target>
        <SimpleButton>
          <IconFilter className="h-4 fill-none stroke-doubleNickel-gray-700" />
          Filters
          <Badge size="xs" color="gray" variant="light">
            {getTotalSelectedCount()}
          </Badge>
        </SimpleButton>
      </Menu.Target>

      <Menu.Dropdown className="rounded-lg">
        <div className="flex flex-col gap-2 py-2">
          <div className="flex flex-row items-center pr-4 text-sm">
            {selectedSubmenu && (
              <ActionIcon
                variant="subtle"
                onClick={() => setSelectedSubmenu('')}
              >
                <IconChevronLeft className="fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
            )}
            <Menu.Label className="capitalize">
              {selectedSubmenu || 'Filters'}
            </Menu.Label>
            <Box
              className="ml-auto text-xs text-doubleNickel-brand-500 hover:cursor-pointer hover:underline"
              onClick={() => {
                if (selectedSubmenu) {
                  const filter = filters.find(
                    (f) => f.title.toLowerCase() === selectedSubmenu
                  )
                  if (filter) handleClear(filter)
                } else {
                  handleClearAll()
                }
              }}
            >
              {selectedSubmenu ? 'Clear' : 'Clear all'}
            </Box>
          </div>
          <div className="max-h-[450px] overflow-y-auto">{menuContents()}</div>
        </div>
      </Menu.Dropdown>
    </Menu>
  )
}

export default FilterMenu
