import { forwardRef } from 'react'

import { Box, BoxProps } from '@mantine/core'

interface SimpleButtonProps extends BoxProps {
  children?: React.ReactNode
}

const SimpleButton = forwardRef<HTMLButtonElement, SimpleButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        component="button" // Ensures Mantine Box behaves like a button
        {...props}
        className="flex h-9 flex-row items-center gap-1 text-nowrap rounded border-[1px] border-doubleNickel-gray-300 p-2 text-xs font-medium text-doubleNickel-gray-700 shadow-sm hover:cursor-pointer hover:bg-doubleNickel-gray-100"
      >
        {children}
      </Box>
    )
  }
)

export default SimpleButton
