import dayjs from 'dayjs'

import { CallDirection, CallStatus } from 'src/graphql/types/activityLogs'
import { ApplicantCallConversionStatus } from 'src/graphql/types/callActivity'
import { ApplicantCallConnectedStatus } from 'src/graphql/types/callActivity'
import { LinkType } from 'src/graphql/types/trackingLinks'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import { formatSnakeValue } from 'src/lib/formatters'

export const callStatusColors = {
  [ApplicantCallConversionStatus.CONNECTED]: {
    [ApplicantCallConnectedStatus.BOOKED]: {
      backgroundColor: 'bg-doubleNickel-success-100',
      textColor: 'text-doubleNickel-success-700',
      color: '#ABEFC6',
    },
    [ApplicantCallConnectedStatus.TRANSFERRED]: {
      backgroundColor: 'bg-doubleNickel-success-100',
      textColor: 'text-doubleNickel-success-700',
      color: '#ABEFC6',
    },
    [ApplicantCallConnectedStatus.OTHER]: {
      backgroundColor: 'bg-doubleNickel-brand-25',
      textColor: 'text-doubleNickel-brand-700',
      color: '#BBDFF3',
    },
    [ApplicantCallConnectedStatus.NOT_AVAILABLE]: {
      backgroundColor: 'bg-doubleNickel-brand-25',
      textColor: 'text-doubleNickel-brand-700',
      color: '#BBDFF3',
    },
    [ApplicantCallConnectedStatus.CALLBACK]: {
      backgroundColor: 'bg-doubleNickel-brand-25',
      textColor: 'text-doubleNickel-brand-700',
      color: '#BBDFF3',
    },
    [ApplicantCallConnectedStatus.NOT_INTERESTED]: {
      backgroundColor: 'bg-doubleNickel-warning-100',
      textColor: 'text-doubleNickel-warning-600',
      color: '#FEF0C7',
    },
    [ApplicantCallConnectedStatus.DISQUALIFIED]: {
      backgroundColor: 'bg-doubleNickel-error-100',
      textColor: 'text-doubleNickel-error-500',
      color: '#FECDCA',
    },
    null: {
      backgroundColor: 'bg-doubleNickel-brand-25',
      textColor: 'text-doubleNickel-brand-700',
      color: '#BBDFF3',
    },
  },
  [ApplicantCallConversionStatus.NOT_CONNECTED]: {
    backgroundColor: 'bg-doubleNickel-gray-100',
    textColor: 'text-doubleNickel-gray-500',
    color: '#EAECF0',
  },
}

export const getStatusLabel = (status: string, connectedStatus: string) => {
  if (status == ApplicantCallConversionStatus.NOT_CONNECTED) {
    return formatSnakeValue(status)
  } else {
    return `${formatSnakeValue(status)} ${
      connectedStatus ? `: ${formatSnakeValue(connectedStatus)}` : ''
    }`
  }
}

export const getStatusColor = (status, connectedStatus) => {
  if (status === ApplicantCallConversionStatus.CONNECTED) {
    return `${callStatusColors[status][connectedStatus].backgroundColor} ${callStatusColors[status][connectedStatus].textColor}`
  }
  return `${callStatusColors[status].backgroundColor} ${callStatusColors[status].textColor}`
}

export const getUrlParams = () => {
  const dateRangeUrlString = new URLSearchParams(window.location.search).get(
    'dateRange'
  )
  const dateRange = dateRangeUrlString
    ? dateRangeUrlString.split(',')
    : [dayjs().subtract(7, 'day').startOf('day').toDate(), new Date()]

  const defaultStartDate = new Date(dateRange[0])
  const defaultEndDate = new Date(dateRange[1])

  const callDirectionsUrlString = new URLSearchParams(
    window.location.search
  ).get('callDirections')

  const defaultCallDirections = callDirectionsUrlString
    ? callDirectionsUrlString.split(',')
    : []

  const recruitersUrlString = new URLSearchParams(window.location.search).get(
    'recruiters'
  )

  const defaultRecruiters = recruitersUrlString
    ? recruitersUrlString.split(',')
    : []

  const callTypesUrlString = new URLSearchParams(window.location.search).get(
    'callTypes'
  )

  const defaultCallTypes = callTypesUrlString
    ? callTypesUrlString.split(',')
    : []

  const callStatusesUrlString = new URLSearchParams(window.location.search).get(
    'callStatuses'
  )

  const defaultCallStatuses = callStatusesUrlString
    ? callStatusesUrlString.split(',')
    : []

  const leadSourcesUrlString = new URLSearchParams(window.location.search).get(
    'leadSources'
  )

  const defaultLeadSources = leadSourcesUrlString
    ? leadSourcesUrlString.split(',')
    : []

  const connectedStatusUrlString = new URLSearchParams(
    window.location.search
  ).get('connectedStatus')

  const defaultConnectedStatus = connectedStatusUrlString
    ? connectedStatusUrlString.split(',')
    : []

  const jobListingsUrlString = new URLSearchParams(window.location.search).get(
    'jobListings'
  )

  const defaultJobListings = jobListingsUrlString
    ? jobListingsUrlString.split(',')
    : []

  return {
    defaultStartDate,
    defaultEndDate,
    defaultCallDirections,
    defaultRecruiters,
    defaultCallTypes,
    defaultCallStatuses,
    defaultLeadSources,
    defaultConnectedStatus,
    defaultJobListings,
  }
}

export const updateUrl = (name, value) => {
  const url = new URL(window.location.href)
  if (!value) {
    url.searchParams.delete(name)
  } else {
    url.searchParams.set(name, value)
  }
  window.history.replaceState(null, null, url)
}

export const generateMetricsByDateRange = (
  startDate,
  endDate,
  metrics,
  keys,
  metricParam
) => {
  // Helper function to create a date range between two dates
  function createDateRange(start, end) {
    const dateArray = []
    const currentDate = new Date(start)
    end = new Date(end)

    while (currentDate <= end) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]) // Store only the date part
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
  }

  const defaultValues = keys.reduce((acc, key) => {
    acc[key] = 0
    return acc
  }, {})

  // Helper function to initialize the default object
  function getDefaultMetricsObject(date) {
    return {
      date: dayjs(date).format('MM/DD'),
      ...defaultValues,
    }
  }

  // Create date range
  const dateRange = createDateRange(startDate, endDate)

  // Create a map to store the metrics by date
  const metricsMap = {}

  // Iterate over the metrics array and populate the metricsMap
  metrics.forEach((metric) => {
    const metricDate = metric.date.split('T')[0] // Only use the date part
    if (!metricsMap[metricDate]) {
      metricsMap[metricDate] = getDefaultMetricsObject(metricDate)
    }
    metricsMap[metricDate][formatSnakeValue(metric[metricParam])] +=
      metric.count
  })

  // Generate the final array with default metrics for missing dates
  return dateRange.map((date) => {
    return metricsMap[date] || getDefaultMetricsObject(date)
  })
}

export const callTypeOptions = [
  { value: 'TWILIO', label: 'Recruiter' },
  { value: 'BLANDAI', label: 'Voice Agent' },
]

export const leadSourceOptions = Object.keys(LinkType).map((key) => ({
  value: LinkType[key],
  label: LinkTypeDisplay[LinkType[key]],
}))

export const connectedStatusOptions = Object.keys(ApplicantCallConnectedStatus)
  .filter(
    (key) =>
      ApplicantCallConnectedStatus[key] !==
      ApplicantCallConnectedStatus.CALLBACK
  )
  .map((key) => ({
    value: ApplicantCallConnectedStatus[key],
    label: formatSnakeValue(ApplicantCallConnectedStatus[key]),
  }))

export const callDirectionOptions = Object.keys(CallDirection).map((key) => ({
  value: CallDirection[key],
  label: formatSnakeValue(CallDirection[key]),
}))

export const callStatusOptions = Object.keys(CallStatus).map((key) => ({
  value: CallStatus[key],
  label: formatSnakeValue(CallStatus[key]),
}))
