export const PDFViewer = ({ url }) => {
  return (
    <iframe
      id="pdf-viewer"
      src={`${url}#navpanes=0`}
      className="h-full w-full"
      title="PDF Document"
    />
  )
}
