import { useContext, useState } from 'react'

import {
  ActionIcon,
  Loader,
  Menu,
  Divider,
  Tooltip,
  Box,
  Button as MantineButton,
} from '@mantine/core'
import TimeAgo from 'react-timeago'

import { Link, navigate, routes } from '@redwoodjs/router'
import { useQuery, useMutation } from '@redwoodjs/web'

import AdminContainer from 'src/components/AdminContainer/AdminContainer'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { GET_APPLICANT_BY_ID } from 'src/graphql/applicants.graphql'
import { UPDATE_APPLICANT } from 'src/graphql/applicants.graphql'
import { LicenseClass } from 'src/graphql/types/licenses'
import { SEND_CALL } from 'src/graphql/voiceAgents.graphql'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconLink from 'src/icons/IconLink'
import IconList from 'src/icons/IconList'
import IconMessage from 'src/icons/IconMessage'
import IconNavigate from 'src/icons/IconNavigate'
import IconNote from 'src/icons/IconNote'
import IconPhone from 'src/icons/IconPhone'
import IconRightArrow from 'src/icons/IconRightArrow'
import IconVoiceMail from 'src/icons/IconVoiceMail'
import {
  buildFullName,
  formatExperience,
  formatSidebarAddress,
} from 'src/lib/formatters'
import {
  unformatPhoneNumber,
  formatInternationalPhoneNumber,
} from 'src/lib/phone.utils'
import { buildApplicationUrl } from 'src/lib/url.utils'

import ApplicantStageRow from './components/ApplicantStageRow'
import Row from './components/Row/Row'
import SidebarActivityLogs from './components/SidebarActivityLogs'
import StepItem from './components/StepItem/StepItem'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const EMPTY_VALUE = '-'

const CustomTab = ({
  applicant,
  selectedTab,
  value,
  label,
  setSelectedTab,
}) => {
  const isSelected = selectedTab === value
  const isActivityLog = value !== 'HIRING_STEPS'

  return (
    <Box
      className={`flex flex-row items-center justify-center gap-1 rounded p-2 text-xs hover:cursor-pointer hover:bg-doubleNickel-gray-50 ${
        isSelected && 'bg-doubleNickel-gray-100 font-medium '
      }`}
      onClick={() => setSelectedTab(value)}
    >
      {label}
      {
        <Tooltip label={`Go to activity log`}>
          <ActionIcon
            variant="subtle"
            size={'xs'}
            className={`${isSelected && isActivityLog ? 'flex' : 'hidden'}`}
            onClick={() =>
              navigate(
                routes.applicantDetails({
                  id: applicant.applicantId,
                  tab: 'activitylog',
                  activityType: value,
                })
              )
            }
          >
            <IconRightArrow
              className={`h-4 fill-none stroke-doubleNickel-gray-500 `}
            />
          </ActionIcon>
        </Tooltip>
      }
    </Box>
  )
}

const ApplicantSidebar = ({
  applicantId,
  showNavigation = false,
  onRegisterCall = () => {},
  onPhoneCall = () => {},
}: {
  applicantId: string
  showNavigation: boolean
  onRegisterCall?: (activityLog) => void
  onPhoneCall?: (applicant) => void
}) => {
  const { close } = useApplicantDrawerContext()
  const recruiters = useContext(RecruitersContext)
  const [selectedTab, setSelectedTab] = useState<string | null>('HIRING_STEPS')
  const { data, loading: loadingApplicant } = useQuery(GET_APPLICANT_BY_ID, {
    variables: {
      id: applicantId,
    },
    onError,
  })
  const applicant = data?.applicant || null

  const [updateApplicant] = useMutation(UPDATE_APPLICANT, {
    refetchQueries: [
      {
        query: GET_APPLICANT_BY_ID,
        variables: {
          id: applicantId,
        },
      },
    ],
    onCompleted: () => {
      toast('Applicant updated successfully', 'success')
    },
    onError,
  })

  const [sendCall, { loading: calling }] = useMutation(SEND_CALL, {
    onCompleted: () => {
      toast('Call initiated successfully', 'success')
    },
    onError: (error) => {
      toast(error.message, 'error')
    },
  })

  const handleRecruiterChange = (value) => {
    updateApplicant({
      variables: {
        id: applicantId,
        input: {
          recruiterId: value,
        },
      },
    })
  }

  const handleApplicantStageChange = (input) => {
    updateApplicant({
      variables: {
        id: applicantId,
        input,
      },
    })
  }

  const handleCallClick = () => {
    sendCall({
      variables: {
        applicantId: applicant.applicantId,
        companyId: applicant.companyId,
        employeeId: applicant.recruiter?.employeeId,
      },
    })
  }

  const licenseInformation = (license) => {
    const licenseClassLabel =
      license.licenseClass == LicenseClass.NO_CDL
        ? 'No CDL'
        : `Class ${license.licenseClass}`

    const endorsements =
      license?.endorsements?.length > 0
        ? `(${license?.endorsements.join(', ')})`
        : ''

    return `${licenseClassLabel} ${endorsements}`
  }

  const fullName = applicant
    ? buildFullName({
        firstName: applicant.firstName,
        middleName: applicant.middleName,
        lastName: applicant.lastName,
      })
    : ''

  return (
    <div className="h-[100%] px-1">
      {loadingApplicant && (
        <Loader size="md" className="flex w-full justify-center" />
      )}
      {applicant && (
        <div className="flex h-full flex-col gap-3 overflow-auto ">
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-row items-center justify-between gap-2 text-lg font-semibold text-doubleNickel-gray-900">
                {fullName}
                <Tooltip
                  label={"Copy this applicant's application url to clipboard"}
                >
                  <ActionIcon
                    variant="subtle"
                    size="xs"
                    className="hover:bg-doubleNickel-gray-100"
                    onClick={() => {
                      const url = buildApplicationUrl(
                        applicant.application.applicationId
                      )
                      navigator.clipboard.writeText(url)
                      toast('Copied to clipboard', 'success')
                    }}
                  >
                    <IconLink className="h-5 fill-none stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Tooltip>
              </div>
              <ApplicantStageRow
                applicant={applicant}
                handleStageChange={handleApplicantStageChange}
              />
            </div>
            <Row tooltipText="Job Listing" value={applicant.jobListing.title} />
            <div className="flex flex-1 flex-col  text-xs">
              <div className="flex-1 text-xs font-medium text-doubleNickel-gray-500">
                Contact Information
              </div>
              <div className="flex flex-1 flex-col text-sm text-doubleNickel-gray-900">
                <div className="flex flex-row items-center gap-1 text-doubleNickel-brand-500 hover:cursor-pointer hover:underline">
                  <Box
                    onClick={(event) => {
                      event.stopPropagation()
                      onPhoneCall({
                        applicantId: applicant.applicantId,
                        applicantFullName: buildFullName(applicant),
                        phone: unformatPhoneNumber(applicant.phone),
                      })
                    }}
                  >
                    {formatInternationalPhoneNumber(applicant.phone)}
                  </Box>
                  <AdminContainer>
                    <Tooltip
                      label="Call the applicant via the voice agent"
                      className="text-xs"
                    >
                      <Box
                        // disabled={calling}
                        onClick={handleCallClick}
                        className="hover:cursor-pointer hover:bg-doubleNickel-gray-100 hover:stroke-doubleNickel-brand-500"
                      >
                        <IconVoiceMail
                          className={`h-3 fill-none stroke-doubleNickel-brand-500  ${
                            calling ? 'animate-spin' : ''
                          }`}
                        />
                      </Box>
                    </Tooltip>
                  </AdminContainer>
                </div>
                {applicant.address && (
                  <div>{formatSidebarAddress(applicant.address)}</div>
                )}
              </div>
            </div>

            <div className="flex flex-1 flex-col text-xs">
              <div className="flex-1 text-xs font-medium text-doubleNickel-gray-500">
                CDL Experience
              </div>
              <div className="flex flex-1 flex-col text-sm  text-doubleNickel-gray-900">
                {applicant.application?.license?.licenseClass && (
                  <Row
                    value={licenseInformation(applicant.application.license)}
                  />
                )}
                {applicant.yearsOfExperience !== null &&
                  applicant.yearsOfExperience !== undefined &&
                  formatExperience(applicant.yearsOfExperience) +
                    ' of experience'}
                {applicant.application?.accidents &&
                  applicant.application.accidents.length > 0 && (
                    <div>
                      {`${applicant.application.accidents.length} accident(s) registered`}
                    </div>
                  )}
              </div>
            </div>
            <Row
              tooltipText="Recruiter"
              value={`${applicant.recruiter?.firstName ?? EMPTY_VALUE}
              ${applicant.recruiter?.lastName ?? ' '}`}
            >
              <Menu shadow="md" position="bottom-end">
                <Menu.Target>
                  <ActionIcon
                    variant="subtle"
                    className="h-6 w-6 bg-doubleNickel-white"
                  >
                    <IconChevronDown className="fill-doubleNickel-white stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  {recruiters.map((employee) => (
                    <Menu.Item
                      key={employee.value}
                      onClick={() => handleRecruiterChange(employee.value)}
                    >
                      {employee.label}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </Row>
          </div>

          <Divider />
          <div className="flex flex-row gap-2">
            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="HIRING_STEPS"
              label="Hiring Steps"
              setSelectedTab={setSelectedTab}
            />

            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="CALL"
              label="Phone Calls"
              setSelectedTab={setSelectedTab}
            />

            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="SMS"
              label="Texts"
              setSelectedTab={setSelectedTab}
            />

            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="NOTE"
              label="Notes"
              setSelectedTab={setSelectedTab}
            />
          </div>
          {selectedTab == 'HIRING_STEPS' &&
            applicant.steps.items.length > 0 && (
              <>
                <div className="flex flex-1 flex-col gap-1 overflow-auto px-1">
                  {applicant.steps.items.map((step) => (
                    <StepItem
                      key={step.stepId}
                      step={step}
                      applicantId={applicant.applicantId}
                    />
                  ))}
                </div>
              </>
            )}
          {(selectedTab === 'NOTE' ||
            selectedTab === 'SMS' ||
            selectedTab == 'CALL') && (
            <div className={`flex flex-1 flex-col gap-4 overflow-hidden`}>
              <SidebarActivityLogs
                applicant={applicant}
                communication={selectedTab}
                onRegisterCall={onRegisterCall}
              />
            </div>
          )}
          <Divider />
          <div className="truncate text-xs text-doubleNickel-gray-500">
            Started <TimeAgo date={applicant.createdAt}></TimeAgo> | Last
            modified <TimeAgo date={applicant.updatedAt}></TimeAgo>
          </div>
          {showNavigation && (
            <MantineButton
              classNames={{
                root: 'bg-doubleNickel-brand-500 text-white text-xs rounded-lg mt-auto min-h-8 hover:bg-doubleNickel-brand-700',
              }}
              component={Link}
              to={routes.applicantDetails({
                id: applicant.applicantId,
                tab: 'application',
              })}
              rightSection={
                <IconNavigate className="h-4 fill-none stroke-white" />
              }
              onClick={close}
            >
              Go to applicant
            </MantineButton>
          )}
        </div>
      )}
    </div>
  )
}

export default ApplicantSidebar
