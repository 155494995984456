import {
  getStatusColor,
  getStatusLabel,
} from 'src/pages/CallAnalyticsPage/utils'

const CallConversionBadge = ({ status, connectedStatus }) => {
  if (!status) {
    return null
  }

  return (
    <div
      className={`text-nowrap rounded px-2 py-1 text-xs
        ${getStatusColor(status, connectedStatus)}`}
    >
      {getStatusLabel(status, connectedStatus)}
    </div>
  )
}

export default CallConversionBadge
