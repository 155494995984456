/* eslint-disable jsx-a11y/media-has-caption */
import { Drawer, Loader } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { useCallDrawerContext } from 'src/context/CallDrawerContext'
import { GET_ACTIVITY_LOG } from 'src/graphql/activityLogs.graphql'

import CallDetails from './CallDetails'

const CallDrawer = () => {
  const {
    activityLogId,
    opened: callDrawerOpened,
    close,
  } = useCallDrawerContext()

  const { data, loading } = useQuery(GET_ACTIVITY_LOG, {
    variables: {
      id: activityLogId,
    },
    skip: !activityLogId,
  })

  const activityLog = data?.activityLog

  return (
    <Drawer
      opened={callDrawerOpened}
      withCloseButton={false}
      onClose={close}
      position="right"
      size="28vw"
      lockScroll={false}
      overlayProps={{ backgroundOpacity: 0.1 }}
      transitionProps={{
        transition: 'slide-left',
        duration: 150,
        timingFunction: 'linear',
      }}
      classNames={{
        title: 'text-base font-semibold text-doubleNickel-gray-900 ',
        body: 'flex flex-col gap-10 h-full',
      }}
    >
      {!loading ? (
        <CallDetails activityLog={activityLog} close={close} />
      ) : (
        <Loader size={'sm'} color="gray" />
      )}
    </Drawer>
  )
}

export default CallDrawer
