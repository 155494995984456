export const GET_MY_COMPANIES = gql`
  query GetMyCompanies {
    getMyCompanies {
      companyId
      companyName
      companyLogo
      employeeId
      isCurrentEmployeeId
      trainingEnabled
      voiceAgentEnabled
    }
  }
`

export const UPDATE_CURRENT_EMPLOYEE_ID = gql`
  mutation UpdateCurrentEmployeeId($input: UpdateCurrentEmployeeIdInput!) {
    updateCurrentEmployeeId(input: $input)
  }
`

export const BEFORE_LOGOUT = gql`
  mutation BeforeLogout {
    beforeLogout
  }
`
