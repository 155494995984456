export enum DocumentCategory {
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  CDL = 'CDL',
  CDLIS = 'CDLIS',
  CLEARING_HOUSE = 'CLEARING_HOUSE',
  CONSENT = 'CONSENT',
  DAC = 'DAC',
  DOT_APP = 'DOT_APP',
  DOT_MEDICAL = 'DOT_MEDICAL',
  DRUG_AND_ALCOHOL_TESTING = 'DRUG_AND_ALCOHOL_TESTING',
  FAST = 'FAST',
  GAFETE_ANAM = 'GAFETE_ANAM',
  GAFETE_UNICO = 'GAFETE_UNICO',
  INE = 'INE',
  MEDICAL_EXAMINATION = 'MEDICAL_EXAMINATION',
  MVR = 'MVR',
  ORIENTATION = 'ORIENTATION',
  OTHER = 'OTHER',
  PAPERWORK = 'PAPERWORK',
  PSP = 'PSP',
  ROAD_TEST = 'ROAD_TEST',
  TWIC_CARD = 'TWIC_CARD',
  VERIFICATION_OF_EMPLOYMENT = 'VERIFICATION_OF_EMPLOYMENT',
  VISA = 'VISA',
}

export const DocumentCategoryLabel = {
  [DocumentCategory.BACKGROUND_CHECK]: 'Background Check',
  [DocumentCategory.CDL]: 'CDL',
  [DocumentCategory.CDLIS]: 'CDLIS',
  [DocumentCategory.CLEARING_HOUSE]: 'Clearinghouse',
  [DocumentCategory.CONSENT]: 'Consent',
  [DocumentCategory.DAC]: 'DAC',
  [DocumentCategory.DOT_APP]: 'DOT Application',
  [DocumentCategory.DOT_MEDICAL]: 'DOT Med Card',
  [DocumentCategory.DRUG_AND_ALCOHOL_TESTING]: 'Drug & Alcohol Testing',
  [DocumentCategory.MEDICAL_EXAMINATION]: 'Medical Examination',
  [DocumentCategory.MVR]: 'MVR',
  [DocumentCategory.ORIENTATION]: 'Orientation',
  [DocumentCategory.OTHER]: 'Other',
  [DocumentCategory.PAPERWORK]: 'Paperwork',
  [DocumentCategory.PSP]: 'PSP',
  [DocumentCategory.ROAD_TEST]: 'Road test',
  [DocumentCategory.TWIC_CARD]: 'TWIC Card',
  [DocumentCategory.VERIFICATION_OF_EMPLOYMENT]: 'VOE',
  [DocumentCategory.VISA]: 'Visa',
  [DocumentCategory.FAST]: 'FAST',
  [DocumentCategory.GAFETE_UNICO]: 'Gafete Único',
  [DocumentCategory.GAFETE_ANAM]: 'Gafete Anam',
  [DocumentCategory.INE]: 'INE',
}

export enum DocumentUserStatus {
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
}

export enum StepStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
}

export enum UploadStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum FileContentType {
  PDF = 'PDF',
  PNG = 'PNG',
  JPG = 'JPG',
  HTML = 'HTML',
  DOC = 'DOC',
  TIF = 'TIF',
  GIF = 'GIF',
  UNKNOWN = 'UNKNOWN',
  ZIP = 'ZIP',
  CSV = 'CSV',
  TXT = 'TXT',
  HEIC = 'HEIC',
}
