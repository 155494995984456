import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Indicator,
  Tooltip,
} from '@mantine/core'

import { useLocation, navigate } from '@redwoodjs/router'

import { ProfileMenu } from 'src/components/Navigation/ProfileMenu/ProfileMenu'
import { GET_APPLICANT_NAME_BY_ID } from 'src/graphql/applicants.graphql'
import { GET_VOE_DRIVER_NAME_BY_ID } from 'src/graphql/voe.graphql'
import IconBell from 'src/icons/IconBell'
import IconMenu from 'src/icons/IconMenu'
import { buildFullName } from 'src/lib/formatters'
import { useWebsockets } from 'src/pages/ApplicantsPage/context/WebSocketsContext'

import GlobalSearchBar from './GlobalSearchBar'

interface HeaderProps {
  toggleNotifications: any
  showSidebarOnMobile?: boolean
  setShowSidebarOnMobile?: (show: boolean) => void
  openSearch?: () => void
}

const Header: React.FC<HeaderProps> = ({
  toggleNotifications,
  showSidebarOnMobile = false,
  setShowSidebarOnMobile,
  openSearch,
}) => {
  const [section, setSection] = useState(null)
  const websocketsData = useWebsockets()
  const [showNewNotificationAlert, setShowNewNotificationAlert] =
    useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    if (websocketsData.newNotification) {
      setShowNewNotificationAlert(true)
    }
    if (websocketsData.newNotification == null) {
      setShowNewNotificationAlert(false)
    }
  }, [websocketsData, pathname])

  const onNotificationClick = () => {
    toggleNotifications.toggle()
  }

  const firstBreadcrumb = pathname.split('/')[1]
  const secondBreadcrumb =
    pathname.length >= 3 &&
    (firstBreadcrumb === 'applicants' || firstBreadcrumb === 'voe-requests')
      ? pathname.split('/')[2]
      : null

  const [loadApplicant] = useLazyQuery(GET_APPLICANT_NAME_BY_ID, {
    onCompleted: (data) => {
      const fullName = buildFullName({
        firstName: data.applicant.firstName,
        middleName: data.applicant.middleName,
        lastName: data.applicant.lastName,
      })
      setSection(fullName)
    },
    onError: (error) => {
      console.error('Error fetching applicant: ', error.message)
    },
  })

  const [loadVoE] = useLazyQuery(GET_VOE_DRIVER_NAME_BY_ID, {
    onCompleted: (data) => {
      setSection(data.verificationOfEmployment.driverName)
    },
    onError: () => {
      console.log('error', 'Unable to fetch VoE')
    },
  })

  useEffect(() => {
    if (secondBreadcrumb) {
      if (firstBreadcrumb === 'applicants') {
        loadApplicant({ variables: { id: secondBreadcrumb } })
      } else if (firstBreadcrumb === 'voe-requests') {
        loadVoE({ variables: { id: secondBreadcrumb } })
      }
    }
  }, [secondBreadcrumb])

  return (
    <div className="flex h-[7%] w-full flex-row items-center justify-between rounded-lg border-b bg-white px-6 py-4">
      <ActionIcon
        variant="subtle"
        className="sm:hidden"
        onClick={() => setShowSidebarOnMobile(!showSidebarOnMobile)}
      >
        <IconMenu
          className={`fill-none stroke-doubleNickel-gray-700 active:stroke-doubleNickel-gray-900`}
        />
      </ActionIcon>
      <Breadcrumbs>
        <Anchor
          onClick={() => navigate(`/${firstBreadcrumb}`)}
          className="rounded-lg p-2 text-sm font-medium capitalize text-doubleNickel-gray-700 "
        >
          {firstBreadcrumb.replaceAll('-', ' ')}
        </Anchor>
        {secondBreadcrumb && (
          <Anchor
            onClick={() => navigate(`/${firstBreadcrumb}/${secondBreadcrumb}`)}
            className="rounded-lg p-2 text-sm font-medium capitalize text-doubleNickel-gray-700 "
          >
            {section}
          </Anchor>
        )}
      </Breadcrumbs>
      <div className="ml-auto flex flex-row items-center gap-6">
        <GlobalSearchBar handleClick={openSearch} />
        <Tooltip key={'Notifications'} label={'Notifications'} position="right">
          <ActionIcon variant="subtle" onClick={onNotificationClick}>
            <Indicator
              size={6}
              color={showNewNotificationAlert ? '#1C93D7' : 'transparent'}
            >
              <IconBell className="h-4 w-4 fill-doubleNickel-white stroke-doubleNickel-gray-400" />
            </Indicator>
          </ActionIcon>
        </Tooltip>
        <ProfileMenu />
      </div>
    </div>
  )
}

export default Header
