import { Box, Loader } from '@mantine/core'

import IconRefresh from 'src/icons/IconRefresh'
import { cn } from 'src/utils'

const RefreshButton = ({ loading, refetch }) => {
  return (
    <Box
      onClick={() => refetch()}
      className={cn(
        'flex w-full flex-row items-center justify-center gap-1 stroke-doubleNickel-gray-800 text-xs hover:cursor-pointer hover:stroke-doubleNickel-brand-500 hover:text-doubleNickel-brand-500',
        loading && 'stroke-doubleNickel-brand-500 text-doubleNickel-brand-500'
      )}
    >
      {loading ? (
        <>
          <Loader size={10} />
          <div>Refreshing</div>
        </>
      ) : (
        <>
          <IconRefresh className="h-3 fill-none" />
          <div>Refresh</div>
        </>
      )}
    </Box>
  )
}

export default RefreshButton
