import dayjs from 'dayjs'
import { ActivityLog } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import { buildFullName } from 'src/lib/formatters'

interface CallLogCardProps {
  activityLog: ActivityLog
}

const MiniCallLog = ({ activityLog }: CallLogCardProps) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isVirtualCall = activityLog.callType === 'BLANDAI'
  const label = isOutboundCall ? 'Outgoing Call' : 'Incoming Call'
  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const isManualRegistered = activityLog.callStatus === null
  const voiceMailTitle = activityLog.voiceMailTemplate?.title

  return (
    <div
      className={`border-bg-doubleNickel-gray-600 flex w-full flex-col gap-1 rounded-sm bg-doubleNickel-gray-50 p-4 text-xs`}
    >
      <div className="flex flex-row items-center gap-2 overflow-auto">
        <div className="flex flex-row items-center gap-2 text-nowrap font-semibold text-doubleNickel-gray-700">
          {label}
        </div>
        {isMissedCall && (
          <StyledBadge textTransform="capitalize" color="red">
            Missed
          </StyledBadge>
        )}
        {isVirtualCall && (
          <StyledBadge textTransform="capitalize" color="blue">
            Virtual
          </StyledBadge>
        )}
        {isManualRegistered && (
          <StyledBadge textTransform="capitalize" color="gray">
            Manual
          </StyledBadge>
        )}
        {isLeftVoiceMail && (
          <StyledBadge textTransform="capitalize" color="red">
            Voicemail{voiceMailTitle && <>: {voiceMailTitle}</>}
          </StyledBadge>
        )}
      </div>
      <div
        className={`flex w-full flex-row justify-between font-medium text-doubleNickel-gray-700`}
      >
        {!voiceMailTitle && !isMissedCall && !isNaN(callInMinutes) && (
          <p>
            {callInMinutes} minutes, {remindingSeconds} seconds
          </p>
        )}
      </div>
      <div
        className={`flex w-full flex-row justify-between text-xs text-doubleNickel-gray-500`}
      >
        {dayjs(activityLog.activityDate).isValid() && (
          <div>
            {dayjs(activityLog.activityDate).format('MM/DD/YYYY hh:mm A')}
          </div>
        )}
        <div>{buildFullName(activityLog.employee)}</div>
      </div>
    </div>
  )
}

export default MiniCallLog
